@import '../node_modules/bootstrap/scss/bootstrap.scss';

  html, body {
    box-sizing      : border-box;
    background-color: #293133 ;
    color           : #293133;
    font-family     : Helvetica, Arial, sans-serif;
    hyphens         : auto;
    -webkit-hyphens : auto;
    -moz-hyphens    : auto;
	}

	body {
		background-color: #293133;
	}
	
  .container-fluid {
    padding-left : 0;
    padding-right: 0;
  }

  a {
    color: #293133;
	}
	
  #carousel-inner-front {
    height: 100vh;
  }

  .carousel-item {
    background-size    : contain;
    background-position: center;
    background-repeat  : no-repeat;
    height             : inherit;
  }

  footer {
    height          : 10vh;
    background-color: #293133;
  }

  .foot-row {
    height: inherit;
  }

  .foot-col {
    display        : flex;
    justify-content: center;
    align-items    : center;
	}


  /* The version of Bourbon used in this Pen was 4.* */
/* @import "bourbon"; */
/* The version of Neat used in this Pen was 1.8.* */
/*@import "neat"; */

$base-duration: 500ms;

// Colors
$color-1: #444;
$color-2: #3498db;
$color-3: #2ecc71;

// Breakpoints
$sm : new-breakpoint(min-width 320px);
$med: new-breakpoint(min-width 768px);
$lg : new-breakpoint(min-width 1024px);

*, *:before, *:after {
	box-sizing: border-box;
	outline   : none;
}

body {
	position: relative;
	margin  : 0;
	padding : 0;
	/* font-smooth: auto; */
	overflow-x: hidden;
}

.menu {
	position        : fixed;
	top             : 20px;
	left            : 20px;
	display         : flex;
	align-items     : center;
	justify-content : center;
	flex-direction  : column;
	width           : 50px;
	height          : 50px;
	border-radius   : 50%;
	background-color: #293133 !important;
	//box-shadow: 0 0 20px rgba(black,0.2);
	transition: transform 250ms ease;
	cursor    : pointer;
	z-index   : 200;
	
	span {
		position        : relative;
		display         : block;
		width           : 50%;
		height          : 2px;
		float           : left;
		transform-origin: center center;
		transition      : transform 250ms ease;
		z-index         : 200;
		
		&:nth-of-type(1) {
			transform       : translateY(-5px);
			background-color: #efc44c;
		}

		&:nth-of-type(2) {
			background-color: #efc44c;
		}
		
		&:nth-of-type(3) {
			transform       : translateY(5px);
			background-color: #efc44c;
		}
	}
}

#menu {
	display: none;
	
	&:checked ~ .menu {
		background-color: transparent;
		transform       : rotate(360deg);
		transition      : transform 250ms ease;
		
		span {
			transition: transform 250ms ease;
			
			&:nth-of-type(1) {
				transform       : translateY(1px) rotate(45deg);
				background-color: #efc44c !important;
			}
			
			&:nth-of-type(2) {
				display: none;
			}
			
			&:nth-of-type(3) {
				transform       : translateY(-1px) rotate(-45deg);
				background-color: #efc44c !important;
			}
		}
	}
	
	&:checked ~ .nav {
		left      : 0px;
		transition: left $base-duration ease;
	}
	
	&:checked ~ main {
		transform : translateX(250px);
		transition: transform $base-duration ease;
	}
}

.nav {
	position  : fixed;
	top       : 0;
	left      : -500px;
	min-height: 100vh;
	height    : 200vh;
	margin    : 0;
	padding   : 0;
	transition: left $base-duration ease;
	z-index   : 0;
	
	ul {
		position       : relative;
		list-style-type: none;
		margin         : 100px 0;
		padding        : 0;
		
		li {
			position: relative;
			display : block;

			a {
				position       : relative;
				display        : block;
				margin         : 0;
				padding        : 15px 20px;
				font-size      : 14px;
				font-weight    : bold;
				text-decoration: none;
				text-transform : uppercase;
				
				&:before {
					position  : absolute;
					content   : '';
					top       : 0;
					left      : 0;
					width     : 0;
					height    : 100%;
					transition: width 250ms ease;
					z-index   : -1;
				}
				
				&:hover {
					
					&:before {
						width           : 100%;
						height          : 100%;
						background-color: rgba(white,1);
						transition      : width 250ms ease;
					}
				}
			}
		}
	}
}

/* Anpassungen für Off-Canvas Navigation */

nav {
  background-color: #FFFFFF !important;
}

label.menu {
  background-color: #293133 !important;
}

#menu:checked ~ .menu span {
  background-color: #293133 !important;
}

.nav ul li {
  border-bottom: 5px solid #293133 !important;
}

.nav ul li a {
  color: #293133 !important;
}

.nav ul li a:hover {
  background-color: #efc44c !important;
}

.header {
	height             : 50vh;
	background-size    : cover;
	background-position: center;
	background-repeat  : no-repeat;
}

footer {
	height: 10vh;
}

.contact-link {
	color          : #708920;
	text-decoration: underline;

	&:hover {
			color: #293133 !important;
	}
}

.contact a{
	&:hover {
		color: yellow;
	}
}

h1 {
	font-weight: bold;
	color      : #efc44c;
}

h2 {
	color: #efc44c;
}

.header-about {
	background-image: url('../images/background_about.jpg');
}

.header-education {
	background-image: url('../images/background_education.jpg');
}

.header-portfolio {
	background-image: url('../images/background_portfolio.jpg');
}

.header-screenshots {
	background-image: url('../images/background_screenshots.jpg');
}

.header-contact {
	background-image: url('../images/background_contact.jpg');
}

article {
	padding         : 30px 10px 30px 10px;
	background-image: linear-gradient(to bottom right, rgba(244,200,65,0.7), rgba(239,189,62,1));
}

address {
	margin-bottom: 0;
}

.carousel-indicators li {
	background-color: #efc44c;
}

mark {
	background-color: #293133;
	color           : #efc44c;
	border-radius   : 10px;
	padding         : 10px;
}

.portfolio-business-link {
	display        : flex;
	justify-content: center;
	
	a {
		background-color: #293133;
		color           : #8d9db8;
		min-width       : 250px;
		text-align      : center;
		border-radius   : 10px;
		padding         : 15px;

		&:hover {
			color          : white;
			text-decoration: none;
		}
	}
}

.portfolio-private-link {
	display        : flex;
	justify-content: center;

	a {
		background-color: white;
		color           : #293133;
		min-width       : 250px;
		text-align      : center;
		border-radius   : 10px;
		padding         : 15px;

		&:hover {
			color          : #708920;
			text-decoration: none;
		}
	}

	.fas {
		margin-right: 10px;
	}
}

.portfolio-without-link {
	display        : flex;
	justify-content: center;
}

.text-without-link {
	background-color: #8d9db8;
	color           : #293133;
	min-width       : 250px;
	text-align      : center;
	border-radius   : 10px;
	padding         : 15px;
}

.img-col {
	text-align     : center;
	justify-content: center;
	align-items    : center;
}

.tag-col {
	background-color: #293133;
	border-radius   : 10px;
	padding         : 15px;

	h5 {
		color: white;
	}
}

.fa-circle {
	
	&.colorone {
		color: #95cbf6;
	}
	
	&.colortwo {
		color: #efc44c;
	}
	
	&.colorthree {
		color: #708920;
	}
	
	&.colorfour {
		color: white;
	}
}

span {
	color: #8d9db8;
}

.fa-circle {
	margin: 0 10px;
}

.icon-img {
	width       : 16px;
	height      : 16px;
	margin-right: 0.7em;
}

.about-row {
	margin-left     : 0;
	margin-right    : 0;
	margin-bottom   : 25px;
	background-color: unset;
}

.about-image {
	border-radius: 10px;
}

.about-link {
	color          : #708920;
	text-decoration: underline;

	&:hover {
		color: #293133 !important;
	}
}

header {
	background-color: #293133;
	padding-top     : 10px;
	padding-bottom  : 10px;
	padding-left    : 25px;
}

.fa-linkedin,
.fa-facebook-square {
	color: #efc44c;

	&:hover {
		color: white;
	}
	
}

.col {
	background-color: unset !important;
	border          : none !important;
}

.tag-col {
	height: 100%;
}

#main-screenshots {

	#carouselscreenshots {

			.carousel-indicators {
					margin-bottom: 50px;
					
					li {
							background-color: #293133;
					}
			}

			.carousel-inner {

					.carousel-item {
							text-align: center;

							.carousel-caption {
								padding-top: 0;

								h4 {
									color: #293133;
								}
								
							}
					}
			}
	}
}

#carouselfront, 
#carouselscreenshots {
    width: 100%;

    .carousel-indicators {

        li {
            height: 10px;
				}
				
    }
}

#main-screenshots {
	height: 650px;

	.carousel-indicators {
		bottom: 90px;
	}

	.carousel-inner {
		height: 650px;
		
		.carousel-item {
			height: 650px;
		}
		
	}

	.carousel-control-prev-icon {
		height: 0;
		width : 0;
		
		&:after {
				content  : '<';
				font-size: 55px;
				color    : #293133;
		}
	}

	.carousel-control-next-icon {
		height: 0;
		width : 0;
		
		&:after {
				content  : '>';
				font-size: 55px;
				color    : #293133;
		}
	}
	
}


#carouselfront {

	.carousel-indicators {
		li {
			background-color: #efc44c;
		}

		.active {
			background-color: #efc44c;
		}
	}

	.carousel-control-prev {
		left: -4%;

		.carousel-control-prev-icon {
			height: 0;
			width : 0;
			
			&:after {
					content  : '<';
					font-size: 55px;
					color    : #efc44c;
			}
		}
		
	}

	.carousel-control-next {
		right: -1.5%;

		.carousel-control-next-icon {
			height: 0;
			width : 0;
			
			&:after {
					content  : '>';
					font-size: 55px;
					color    : #efc44c;
			}
		}
	
	}
	
}

.carousel-control-prev,
.carousel-control-next {
	display: none;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	background-image: none;
}

// Responsive Anpassungen

@media screen and (max-width: 768px) {

	body {
		font-size: 13px;
	}

	header {
		padding: 10px;
	}

	.header {
		height: 30vh;
	}

	.contact-article {
		padding-left: 0;

		.text-title {
			padding-bottom: 0;
		}
	}
	
	.contact-row {
		margin-left: -20px;
	}
	
	.container {
		padding-left : 0;
		padding-right: 0;
	}

	h1 {
		font-size: 2.1rem;
	}
	
	h2 {
		font-size: 1.6rem;
	}

	h3 {
		font-size: 1.35rem;
	}

	h4 {
		font-size: 1.1rem;
	}

	h5 {
		font-size: 0.85rem;
	}

	.col-12 {
		margin-bottom: 25px;
	}

	.tag-col {
		margin-bottom: 50px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}	

	.tag-wrapper-col {
		padding-left : 10px;
		padding-right: 10px;
		
		&:first-of-type {
			padding-left: 0;
		}
		
		&:last-of-type {
			padding-right: 0;
		}
	}

	.img-col {
		margin-bottom: 41px;
	}

	.pic-icon-row {
		display: none;
	}
	
	.text-icon-col {
		padding-left: 0;
	}

	.education-col {
		padding-left: 10px;
	}

	.portfolio-col {
		padding-left: 0;
	}

	.portfolio-business-link {
		padding-left: 10px;
	}

	.education-row {
		margin-left: -10px;
	}

	.title-row {
		padding-left: 0;
	}

	#carouselfront {
		.carousel-indicators {
			bottom: 0;
		}
	}

	.carousel-indicators {
		bottom: 90px;
	}

	.carousel-control-prev,
	.carousel-control-next {
		display: none;
	}

	.education-col {
		margin-bottom: 0;

		h3 {
			margin-bottom: 33px;
		}

		p:last-of-type {
			margin-bottom: 16px;
		}
	}

	.skills-col {
		margin-bottom: 30px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.skills-paragraph {
		
		&:last-of-type {
			margin-bottom: 0 !important;
		}
	}

	.about-row {
		margin-bottom: 0;
	}

	.portfolio-business-link,
	.portfolio-private-link {
		margin-bottom: 20px;

	.portfolio-last-link {
		margin-bottom: 0;
	}
}
	
	#main-screenshots {
		min-height: unset;

		.carousel-item {
			img {
				width     : 80%;
				height    : auto;
				margin-top: 100px;
			}

			.carousel-caption {
				bottom: 150px;
			}
		}

		.carousel-indicators {
			bottom: 85px;
		}
	}

	.googlemaps-row {
		margin-bottom: 0;
	}

	address {
		margin-bottom: 25px;
	}

}

@media screen and (min-width: 769px) {
	#main-screenshots {
		.carousel-caption {
			bottom: 160px;
		}
		
		.carousel-item {
			img {
				margin-top: 50px;
			}
		}
		
	}

	.education-col {
		p:last-of-type {
			margin-bottom: 0;
		}
		
	}

	.text-title {
		padding-bottom: 25px !important;
	}
	
}

.contact-col {
	padding      : 0;
	margin-left  : 0;
	padding-right: 10px;
}

.contact-col-title {
	padding-left : 0;
	padding-right: 0;
}

.address,
.contact, 
.email {
	margin-bottom: 10px;
}

.contact-row {
	padding-left : 30px;
	padding-right: 30px;
}

.googlemaps-row {
	text-align    : center;
	position      : relative;
	padding-bottom: 56%;
	height        : 0;
	overflow      : hidden;

	iframe {
		position: absolute;
		top     : 0;
		left    : 0;
		width   : 100%;
		height  : 100%;
	}
}

.header {
	border-radius: 10px 10px 0 0;
}

footer {
	border-radius: 0 0 10px 10px;
}

.skills-paragraph {
	background-color: #293133;
	color           : #8d9db8;
	border-radius   : 10px;
	padding         : 20px;
	text-align      : center;
	height          : 100%;

	mark {
		background-color: white;
		color           : #293133;
	}
}

.tag-row {
	margin-bottom: 0;
}

.pic-icon-row {
	margin-bottom: 41px;
}

.education-row {
	margin-bottom: 0;
}

.planned-project-row {
	&:last-of-type {
		margin-bottom: 0;
	}
}

.private-project-title {
	margin-top: 50px;
}

.portfolio-business-link {
	margin-bottom: 0;
	
	a {
		margin-bottom: 20px;
	}
	
}

.portfolio-private-link {
	margin-bottom: 0;
	
	a {
		margin-bottom: 20px;
	}
	
}

.text-without-link {
	margin-bottom: 20px;
}